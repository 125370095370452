import React from "react"
import { Link, graphql } from "gatsby"
import "./base-styles.scss"
import "./LayoutCommon"
import { baseClass, childClass } from "./LayoutCommon";
import SiteHeader from "components/headers/SiteHeader";
import { Mdx, FileConnection, MdxConnection } from "@GraphQLModels";
import { Pedal, PedalMaker, PedalCategory } from "@ViewModels";
import { PedalBrowserGrid, PedalBrowserNavigator, PedalBrowserTitleBar, PedalBrowserContent, leadInBlockClassName, PedalBrowserNavigatorButton } from "components/pedal-browser/PedalBrowser";
import MdxBlock from "components/texty/MdxBlock";
import PedalCardList from "components/pedal-browser/PedalCardList";
import PageMeta from "components/texty/PageMeta";
import SiteFooter from "components/footer/SiteFooter";

interface IProps {
  pageContext: {
    id: string,
    pedalRegex: string,
    makerName: string
  };
  data: {
    mdx: Mdx,
    allFile: FileConnection,
    allMdx: MdxConnection
  }
}

export default function MakerPageLayout(props: IProps) {
  if (!props.data.allFile) {
    return <h1>No pedals!</h1>;
  }
  const pedals = props.data.allFile.edges.map(x => Pedal.fromFileNode(x.node));
  const makers = props.data.allMdx.edges.filter(x => x.node.frontmatter.templateKey == 'maker').map(x => PedalMaker.fromMdxNode(x.node));
  const categories = props.data.allMdx.edges.filter(x => x.node.frontmatter.templateKey == 'category').map(x => PedalCategory.fromMdxNode(x.node));
  const thisMaker = makers.find(x => x.route == `/${props.pageContext.makerName}/`);
  return (
    <div className={baseClass}>
      <PageMeta
        title={thisMaker.name}
        route={props.data.mdx.fields.route}
        imageUrl={props.data.mdx.frontmatter.mainImage} >
      </PageMeta>

      <SiteHeader />
      <PedalBrowserGrid className={childClass('container')} canHide={true}>
        <PedalBrowserTitleBar title={props.pageContext.makerName} controls={<PedalBrowserNavigatorButton />} />
        <MdxBlock mdx={props.data.mdx} showTitle={false} className={leadInBlockClassName} />
        <PedalBrowserNavigator
          makers={makers}
          activeMaker={thisMaker}
          categories={categories}
          activeCategory={undefined}
        />
        <PedalBrowserContent>
          <h3 className={childClass('section-seperator-title')}>All {props.data.mdx.frontmatter.title} Pedals</h3>
          <PedalCardList pedals={pedals} />
        </PedalBrowserContent>
      </PedalBrowserGrid>
      <SiteFooter />
    </div>
  )
}

export const pageQuery = graphql`
  query MakerPageLayout($id: String, $pedalRegex: String) {
        mdx(id: {eq: $id }) {
        id
        fields {
          route
        }
        frontmatter {
          title
          mainImage
        }
        code {
          body
        }
      }
      allFile( filter: {dir: {regex: $pedalRegex } } ) {
        edges {
          node {
            childMdx {
              id
              frontmatter {
                title
              }
            }
            name
            relativeDirectory
          }
        }
      }
      allMdx( filter: {frontmatter: {templateKey: { in: [ "maker", "category" ] } } } ) {
        edges {
          node {
            fields {
              route
            }
            frontmatter {
              templateKey
              mainImage
              title
            }
          }
        }
      }
    }
`
